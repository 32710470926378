<template>
  <IAmOverlay :loading="loading || !documentDataToAdd.parent">
    <b-card id="document-add">
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form>
          <!-- SECTION Document information -->
          <b-card
            header-tag="header"
            border-variant="info"
            header-bg-variant="light-info"
            header-class="py-1"
            no-body
          >
            <template #header>
              <h6 class="m-0">
                {{ $t('document.infoDocs') }}
              </h6>
            </template>
            <b-card-body class="py-0 px-1">
              <b-row class="mt-1">
                <!-- ANCHOR Document Name - required -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('document.docsName')"
                    rules="required"
                  >
                    <b-form-group label-for="documentName">
                      <template #label>
                        <div class="text-heading-5">
                          {{ $t('document.docsName') }}
                          <span class="text-danger">(*)</span>
                        </div>
                      </template>
                      <b-form-input
                        id="documentName"
                        v-model="documentDataToAdd.name"
                        :state="getValidationState(validationContext) === false ? false : null"
                        lazy-formatter
                        :formatter="trimInput"
                        :placeholder="$t('document.ph.docsName')"
                      />
                      <!-- maxlength="50" -->

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR Document folder - required -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('document.Folder')"
                    rules="required"
                  >
                    <b-form-group label-for="documentFolder">
                      <template #label>
                        <div class="text-heading-5">
                          {{ $t('document.Folder') }}
                          <span class="text-danger">(*)</span>
                        </div>
                      </template>
                      <v-select
                        v-model="documentDataToAdd.parent"
                        :state="getValidationState(validationContext) === false ? false : null"
                        :options="folderList"
                        :reduce="(val) => val.id"
                        :clearable="false"
                        label="id"
                        searchable
                        disabled
                        :filterable="false"
                        :loading="loading"
                        :placeholder="$t('document.ph.folder')"
                        @open="openFolder"
                        @search="searchFolder"
                      >
                        <template #selected-option="data">
                          <span class="font-weight-bold d-block text-nowrap">
                            {{ data.name }}
                          </span>
                        </template>
                        <template #option="data">
                          <span class="font-weight-bold d-block text-nowrap">
                            {{ data.name }}
                          </span>
                        </template>
                        <template #spinner="{ loading }">
                          <div
                            v-if="loading"
                            style="border-left-color: rgba(88, 151, 251, 0.71)"
                            class="vs__spinner"
                          />
                        </template>
                        <template #no-options>
                          Loading...
                        </template>
                      </v-select>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR Description -->
                <b-col cols="12">
                  <validation-provider
                    #default="validationContext"
                    :name="$t('document.description')"
                    rules=""
                  >
                    <b-form-group label-for="description">
                      <template #label>
                        <div class="text-heading-5">
                          {{ $t('document.description') }}
                        </div>
                      </template>

                      <quill-editor
                        ref="quillRef"
                        v-model="documentDataToAdd.description"
                        :options="editorOption"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- ANCHOR file-->
                <b-col
                  cols="12"
                  md="12"
                >
                  <validation-provider
                    #default="validationContext"
                    name="File"
                    :rules="`size:${MAX_SIZE_TO_UPLOAD_FILE}`"
                  >
                    <b-form-group>
                      <!-- label-for="file" -->
                      <template #label>
                        <div class="text-heading-5">
                          {{ $t('document.file') }}
                          <em v-if="MAX_SIZE_TO_UPLOAD_FILE">(Dung lượng tối đa: {{ sizeFormatter(MAX_SIZE_TO_UPLOAD_FILE) }})</em>
                          <!-- <span class="text-danger">(*)</span> -->
                        </div>
                      </template>

                      <div>
                        <div
                          v-for="(fileDocument, indexDocument) in fileDocuments"
                          :key="indexDocument"
                          class="font-weight-bold d-flex-center justify-content-start"
                        >
                          {{ indexDocument + 1 }}. {{ fileDocument.name }} ({{ sizeFormatter(fileDocument.size) }})
                          <b-button
                            variant="flat-danger"
                            class="p-50 ml-25"
                            @click="handleRemoveFileAdded"
                          >
                            <feather-icon
                              icon="XIcon"
                              size="14"
                            />
                          </b-button>
                        </div>
                      </div>

                      <div class="d-flex mt-25">
                        <b-form-file
                          id="file"
                          v-model="newFileToAdd"
                          multiple
                          class="py-0"
                          accept="*"
                          :state="getValidationState(validationContext) === false ? false : null"
                          style="z-index: 0"
                        />
                      </div>

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
          <!-- !SECTION -->

          <!-- SECTION Document status -->
          <b-card
            header-tag="header"
            border-variant="info"
            header-bg-variant="light-info"
            header-class="py-1"
            class="border mt-1"
            no-body
          >
            <template #header>
              <h6 class="m-0">
                {{ $t('document.docsStatus') }}
              </h6>
            </template>

            <b-card-body class="px-1 py-0">
              <b-row class="mt-1">
                <!-- ANCHOR Status - required -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider>
                    <b-form-group label-for="status">
                      <template #label>
                        <div class="text-heading-5">
                          {{ $t('document.isShowTitle') }}
                        </div>
                      </template>
                      <div class="d-flex">
                        <b-form-checkbox
                          id="status"
                          v-model="documentDataToAdd.isShow"
                          switch
                        />
                        <span>{{ documentDataToAdd.isShow ? $t('document.forF3') : $t('document.forF2') }}</span>
                      </div>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider>
                    <b-form-group label-for="isActive">
                      <template #label>
                        <div class="text-heading-5">
                          {{ $t('document.isActiveTitle') }}
                        </div>
                      </template>
                      <div class="d-flex">
                        <b-form-checkbox
                          id="isActive"
                          v-model="documentDataToAdd.isActive"
                          switch
                        />
                        <span>{{ documentDataToAdd.isActive ? $t('document.publish') : $t('document.draft') }}</span>
                      </div>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider>
                    <b-form-group label-for="">
                      <template #label>
                        <div class="text-heading-5">
                          {{ $t('document.isPinTitle') }}
                        </div>
                      </template>
                      <div class="d-flex">
                        <b-form-checkbox
                          id="isPin"
                          v-model="documentDataToAdd.isPin"
                          switch
                        />
                        <span>{{ $t('document.isPinTitle') }}</span>
                      </div>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- ANCHOR Document folder - required -->
                <b-col
                  v-if="documentDataToAdd.isPin"
                  cols="12"
                  md="6"
                >
                  <validation-provider :name="$t('document.priorityPin.title')">
                    <b-form-group label-for="priorityPinTitle">
                      <template #label>
                        <div class="text-heading-5">
                          {{ $t('document.priorityPin.title') }}
                        </div>
                      </template>
                      <v-select
                        id="priorityPinTitle"
                        v-model="documentDataToAdd.priorityPin"
                        :options="PRIORITY_PIN"
                        :reduce="(val) => val.key"
                        :clearable="false"
                        label="key"
                        :loading="loading"
                        :placeholder="$t('document.ph.folder')"
                      >
                        <template #selected-option="data">
                          <span class="font-weight-bold d-block text-nowrap">
                            {{ $t(`document.priorityPin.${data.key}`) }}
                          </span>
                        </template>
                        <template #option="data">
                          <span class="font-weight-bold d-block text-nowrap">
                            {{ $t(`document.priorityPin.${data.key}`) }}
                          </span>
                        </template>
                      </v-select>
                      <small class="text-danger">
                        {{ $te(`document.priorityPin.desc${documentDataToAdd.priorityPin}`)
                          ? $t(`document.priorityPin.desc${documentDataToAdd.priorityPin}`) : '' }}
                      </small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
          <!-- !SECTION -->

          <!-- SECTION BACK, SAVE Buttons -->
          <b-row>
            <b-col
              cols="12"
              class="d-flex-center"
            >
              <!-- Back button -->
              <b-button
                variant="outline-danger"
                class="mx-50 px-3"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="backHandle"
              >
                {{ $t('back') }}
              </b-button>

              <!-- Save button -->
              <b-button
                variant="gradient"
                class="mx-50 px-3"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                :disabled="loading"
                @click="handleSubmit(submit)"
              >
                <div v-if="loading">
                  <b-spinner small />
                  {{ $t('saving') }}
                </div>
                <span v-else>
                  {{ $t('save') }}
                </span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </IAmOverlay>
</template>

<script>
import {
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormInvalidFeedback,
  BButton,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BSpinner,
} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
import Quill from 'quill'
import {
  onUnmounted, ref, onMounted, computed, watch,
} from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import cloneDeep from 'lodash/cloneDeep'

import { PRIORITY_PIN } from '@/constants/document'
import { useRouter } from '@/@core/utils/utils'
import store from '@/store'
import {
  typeDocumentOptions,
  statusDocumentOptions,
} from '@/constants/selectOptions'
import IAmOverlay from '@/components/IAmOverlay.vue'

import { sizeFormatter } from '@core/utils/filter'
import { trimInput } from '@core/comp-functions/forms/formatter-input'
import formValidation from '@core/comp-functions/forms/form-validation'

import {
  required,
  size,
  max,
} from '@validations'
import documentStoreModule from '@document/documentStoreModule'
import useDocumentHandle from '@document/useDocumentHandle'

export default {
  components: {
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BSpinner,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    IAmOverlay,
    quillEditor,
  },
  setup() {
    // Setup Quill fontSize
    const fontSizeArr = ['8px', '9px', '10px', '12px', '14px', '16px', '18px', '20px', '24px', '32px', '42px', '54px', '68px', '84px', '98px']
    const sizeStyle = Quill.import('attributors/style/size')
    sizeStyle.whitelist = [...fontSizeArr]
    Quill.register(sizeStyle, true)

    const fontStyleArr = ['Lato', 'arial', 'tinos', 'inconsolata', 'roboto', 'montserrat', 'sofia']
    const fontStyle = Quill.import('formats/font')
    fontStyle.whitelist = fontStyleArr
    Quill.register(fontStyle, true)

    const editorOption = ref({
      syntax: false,
      modules: {
        toolbar: [
          [{ font: fontStyleArr }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ size: fontSizeArr }],
          [{ color: [] }, { background: [] }],
          ['blockquote', 'code-block', 'link'],
          [{ align: [] }],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ script: 'sub' }, { script: 'super' }],
          [{ indent: '-1' }, { indent: '+1' }],
          ['image', 'video'],
        ],
      },
      placeholder: 'Vui lòng nhập nội dung...',
    })
    const MAX_SIZE_TO_UPLOAD_FILE = process.env.VUE_APP_MAX_SIZE_FOR_UPLOAD_FILE
    const DOCUMENT_APP_STORE_MODULE_NAME = 'app-documents'

    // Register module
    if (!store.hasModule(DOCUMENT_APP_STORE_MODULE_NAME)) store.registerModule(DOCUMENT_APP_STORE_MODULE_NAME, documentStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DOCUMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(DOCUMENT_APP_STORE_MODULE_NAME)
    })

    const { router, route } = useRouter()

    const blankDocumentData = ref({
      type: 'DOCUMENT',
      parent: null,
      files: [],
      url: null,
      name: null,
      isActive: true,
      isShow: false,
      description: null,
      isPin: false,
      priorityPin: 'LOW',
    })

    const documentDataToAdd = ref(cloneDeep(blankDocumentData.value))

    const resetDocumentData = () => {
      documentDataToAdd.value = cloneDeep(blankDocumentData.value)
    }
    const { refFormObserver, getValidationState, resetForm } = formValidation(resetDocumentData)
    const {
      addDocument,
      openFolder,
      searchFolder,
      folderList,
      getDocumentById,
      handleUploadFiles,
      dataURLtoFile,
    } = useDocumentHandle()
    const fileDocuments = ref([])
    const newFileToAdd = ref()

    const loading = ref(false)

    onMounted(() => {
      loading.value = false
    })
    const folderId = computed(() => route.value.params.id)

    watch(folderId, async id => {
      let folder
      await getDocumentById(id)
        .then(res => { folder = res.data })
      documentDataToAdd.value.parent = folder
    }, { immediate: true })

    const quillRef = ref()

    const submit = async () => {
      loading.value = true
      try {
        let content = quillRef.value.value
        const imageArr = quillRef.value.quill?.editor?.delta?.ops
          .filter(item => item?.insert?.image && item?.insert?.image.startsWith('data:image'))
          .map(item => item?.insert?.image)
        await Promise.all(imageArr.map(async (img, index) => {
          const file = dataURLtoFile(img, index)
          const uploadedFile = await handleUploadFiles([file])
          content = content.replace(img, uploadedFile[0]?.url)
        }))

        let files = []
        if (fileDocuments.value) {
          files = await handleUploadFiles(fileDocuments.value)
        }

        documentDataToAdd.value.files = files
        const payload = {
          ...documentDataToAdd.value,
          description: content,
          parent: +documentDataToAdd.value.parent.id,
        }
        await addDocument(payload, () => {
          router.push({ name: 'apps-documents-list', params: { id: +documentDataToAdd.value.parent.id } })
        })
      } catch (error) {
        console.error({ error })
      } finally {
        loading.value = false
      }
    }

    function backHandle() {
      this.$router.go(-1)
    }

    watch(() => newFileToAdd, () => {
      if (newFileToAdd.value) {
        fileDocuments.value.push(...newFileToAdd.value)
        newFileToAdd.value = null
      }
    }, { deep: true })

    function handleRemoveFileAdded(index) {
      fileDocuments.value.splice(index, 1)
    }

    return {
      documentDataToAdd,
      submit,
      addDocument,
      refFormObserver,
      getValidationState,
      resetForm,

      folderList,
      openFolder,
      searchFolder,

      // select options
      typeDocumentOptions,
      statusDocumentOptions,

      // Validations
      required,
      size,
      max,

      backHandle,
      fileDocuments,
      loading,

      trimInput,
      newFileToAdd,
      sizeFormatter,
      handleRemoveFileAdded,
      MAX_SIZE_TO_UPLOAD_FILE,
      editorOption,
      quillRef,
      PRIORITY_PIN,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
#document-add::v-deep {
  p {
    line-height: unset !important;
  }
}
</style>
